import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {appDatabasePrimaryFunctions, base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import sampleCardImage from '../../styles/images/card_image.svg'
import ScratchCard from 'react-scratchcard-v2';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CustomCSS.css';
import {validateEmail, getObjectFromArray} from '../utils/HelpfulFunction';
import { Textfit } from 'react-textfit';

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: props.appStorage.getItem('userEmail') || props.appStorage.getItem('tempToken') || false,
          active: false,
          width: 0,
          height: 0,
          numberOfMoves: 0,
          alreadyUpdate: "",
          currentGameId: null,
          currentGameTime: false,
          currentResponseArray: [],
          startConfetti: false,
          rotationStarted: false,
          optedIntoEmail: {},
          tierMessaging: [],
          bingoSquares: [],
          modal: false,
          userHighScore: null,
          guess1 : null,
          guess2 : null,
          locationChecking: true,
          loading: true
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false,
              numberOfMoves: 0
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      this.activeRef = base.listenTo('currentGame/active', {
        context: this,
        then(dataActive){
          if(this.props.variables && this.props.variables.collectDistance && dataActive){
            this.checkUsersLocation();
          } else {
            this.setState({
              active: dataActive,
              locationChecking: false,
              loading:false
            })
            if(dataActive){
              this.getSquares();
            }
          }
        }
      })

      this.bingoSquaresRef = base.listenTo('currentGame/bingoSquares', {
        context: this,
        then(data){
          this.setState({
            gameBingoSquaresArray: data,
            bingoSquaresObject: getObjectFromArray(data, "id", "key")
          })
        }
      })

      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!validateEmail(userEmail)){
        base64EncodedEmail = userEmail;
      }

      this.didWinRef = base.listenTo('userAnswers/' + base64EncodedEmail + "/won", {
        context: this,
        then(data){
          if(data === true){
            this.fireWinningMessage(true);
          } else if(typeof data === 'string') {
            this.fireWinningMessage(false);
          }
        }
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0);
      this.props.loadPage();
    }

    async getSquares(userId, activateLoading){
      if(activateLoading){
        this.setState({loading: true})
      }
      if(!userId){
        const userEmail = this.state.userEmail;
        userId = btoa(userEmail);
        if(!validateEmail(userEmail)){
          userId = userEmail;
        }
      }
      const userAnswers = await base.fetch(`userAnswers/${userId}`, {context:this,asArray:true});
      let bingoSquares;
      if(userAnswers && userAnswers.length > 0){
        bingoSquares = userAnswers;
      } else {
        let bingoCardSize = await appDatabasePrimaryFunctions.ref('currentGame/bingoCardSize').once('value');
        bingoSquares = this.state.gameBingoSquaresArray || [];
        this.shuffle(bingoSquares);
        bingoCardSize = bingoCardSize.val() || 9;
        bingoSquares = bingoSquares.slice(0, bingoCardSize);
        const toSaveObject = {};
        for(const i in bingoSquares){
          const square = bingoSquares[i];
          let id = square.id || square.key;
          toSaveObject[id] = {};
          toSaveObject[id]['id'] = id;
          toSaveObject[id]['location'] = i;
        }
        bingoSquares = toSaveObject;
        try {
          await appDatabasePrimaryFunctions.ref(`userAnswers/${userId}`).set(toSaveObject);
        } catch (e) {
          console.log(e);
          const tenantVariables = this.props.variables || {};
          confirmAlert({
            title: tenantVariables.toLateToGetBoardHeader || "Oh No!",
            variables: this.props.variables,
            message: tenantVariables.toLateToGetBoardBody || "All boards have been given out, come back next time!",
            buttons: []
          });
          return;
        }
      }
      if(!Array.isArray(bingoSquares)){
        const newArrayToCreate = [];
        for(const i in bingoSquares){
          newArrayToCreate.push(bingoSquares[i]);
        }
        bingoSquares = newArrayToCreate
      }
      this.setState({
        bingoSquares: bingoSquares,
        arrayOfScratchedSquares: []
      })
      if(activateLoading){
        this.setState({loading: false})
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.activeRef);
      base.removeBinding(this.bingoSquaresRef);
      base.removeBinding(this.didWinRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        this.props.appStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true
          });
          this.getSquares();
        } else if(this.props.variables.canPlay) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true,
            locationCheckFailed: true
          });
          this.getSquares();
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        this.props.appStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables;
      const locationPermissions = this.props.appStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]];
      }
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    winnerToggle() {
      this.setState({
        winnerModal: !this.state.winnerModal,
        startConfetti: false,
        downloadedFile: null
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      this.props.appStorage.removeItem('userEmail');
      this.props.appStorage.removeItem('verifiedAge');
      this.props.appStorage.removeItem('birthday');
      this.props.appStorage.removeItem('locationPermissions');
      this.props.appStorage.removeItem('id_token');
      this.setState({
        userEmail:false
      })
    }

    fireWinningMessage(didWin){
      const variables = this.props.variables;
      const vm = this;
      if(didWin){
        this.setState({
          startConfetti: true
        })
      }
      let messageHeader = variables.winningHeader;
      let messageBody = variables.winningMessage;
      if (!messageHeader) {
        messageHeader = "You Win!";
      }
      if (!messageBody) {
        messageBody = "Congratulations";
      }
      if(!didWin) {
        messageHeader = variables.losingHeader || "Bingo but no prizes left!";
        messageBody = variables.losingMessage;
      }
      confirmAlert({
        variables: variables,
        title: messageHeader,
        message: messageBody,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              vm.setState({startConfetti: false})
            }
          }
        ]
      })
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
      const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";

      return(
          <>
              <div className="hero-text-container">
                <img src={frontLogoImage} className="main-hero-image" alt=""/>
              </div>
              <p style={{color:logOutButtonColor}}>
                <span className="emphasizedText">{playingTextHeader}</span>
                <br/>
                <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:playingTextBody}}/>
              </p>
          </>
        )
    }

    checkForCheckedBingo(item, itemId){
      const arrayOfScratchedSquares = this.state.arrayOfScratchedSquares || [];
      if(arrayOfScratchedSquares.indexOf(itemId) === -1){
        arrayOfScratchedSquares.push(itemId);
      }
      const userSquares = this.state.bingoSquares || [];
      const totalSquares = userSquares.length;
      userSquares.sort((a, b) => a.location - b.location)
      for(const i in userSquares){
        const userSquareId = userSquares[i].id || userSquares[i].key;
        if(userSquares[i].scratched && arrayOfScratchedSquares.indexOf(userSquareId) === -1){
          arrayOfScratchedSquares.push(userSquareId)
        }
      }
      const rowLength = Math.sqrt(totalSquares);
      if(arrayOfScratchedSquares.length < rowLength){
        return;
      }
      let winningRow = [];
      const winningRows = [];
      const winningColumns = [];
      const winningDiagonals = [];
      let count = 0;
      let row = 0;
      for(const i in userSquares){
        count++;
        let userSquare = userSquares[i];
        if(!winningColumns[i%rowLength]){
          winningColumns[i%rowLength] = [];
        }
        let squareId = userSquare.id || userSquare.key;
        winningColumns[i%rowLength].push(squareId);
        const halfWayPointAvailable = totalSquares % 2 === 1;
        let halfWayPoint;
        if(halfWayPointAvailable && totalSquares > 8){
          halfWayPoint = Math.floor((totalSquares / 2));
        }
        if(halfWayPoint && halfWayPoint === parseInt(i)){
          winningDiagonals[0].push(squareId);
          winningDiagonals[1].push(squareId);
        } else if(row === winningRow.length){
          if(!winningDiagonals[0]){
            winningDiagonals[0] = [];
          }
          winningDiagonals[0].push(squareId);
        } else if((row + winningRow.length) === (rowLength - 1)){
          if(!winningDiagonals[1]){
            winningDiagonals[1] = [];
          }
          winningDiagonals[1].push(squareId);
        }
        winningRow.push(squareId);
        if(winningRow.length >= rowLength){
          winningRows.push(winningRow);
          winningRow = [];
          row++;
        }
      }
      let isWinner = false;
      for(let t in winningRows){
        if(!isWinner && winningRows[t].indexOf(itemId) !== -1){
          isWinner = this.checkForMatchingRows(winningRows[t], arrayOfScratchedSquares);
        }
      }
      if(!isWinner){
        for(let j in winningColumns){
          if(!isWinner && winningColumns[j].indexOf(itemId) !== -1){
            isWinner = this.checkForMatchingRows(winningColumns[j], arrayOfScratchedSquares);
          }
        }
      }
      if(!isWinner){
        for(const z in winningDiagonals){
          if(!isWinner && winningDiagonals[z].indexOf(itemId) !== -1){
            isWinner = this.checkForMatchingRows(winningDiagonals[z], arrayOfScratchedSquares);
          }
        }
      }
      if(isWinner){
        const variables = this.props.variables;
        confirmAlert({
          variables: variables,
          title: variables.checkedBingoHeader || "Verifying Stats",
          message: variables.checkedBingoMessage,
          buttons: [
            {
              label: 'OK'
            }
          ]
        })
      }
    }

    checkForMatchingRows(arr1, arr2){
      for(const i in arr1){
        const squareId = arr1[i];
        if(arr2.indexOf(squareId) === -1){
          return false
        }
      }
      return true;
    }

    async setSquareScratched(item, index){
      const userEmail = this.state.userEmail;
      let userId = btoa(userEmail);
      if(!validateEmail(userEmail)){
        userId = userEmail;
      }
      const itemId = item.id || item.key;
      this.checkForCheckedBingo(item, itemId);
      try {
        await appDatabasePrimaryFunctions.ref(`userAnswers/${userId}/${itemId}/scratched`).set(true);
        const bingoSquaresCopy = this.state.bingoSquares;
        bingoSquaresCopy[index]['scratched'] = true;
        await this.setState({
          bingoSquares: bingoSquaresCopy
        })
        const arrayOfScratchedSquares = this.state.arrayOfScratchedSquares || [];
        if(arrayOfScratchedSquares.indexOf(itemId) === -1){
          arrayOfScratchedSquares.push(itemId)
          this.setState({
            arrayOfScratchedSquares: arrayOfScratchedSquares
          })
        }
      } catch (e) {
        console.log("Error saving scratched")
        console.log(e)
      }
    }

    disablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).addEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });

      document.querySelector(element).addEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    disableDisablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).removeEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });
      document.querySelector(element).removeEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    handleTouchStart(e){
      document.body.classList.add("lock-background");
      this.disablePullToRefresh();
    }

    handleTouchEnd(e){
      document.body.classList.remove("lock-background");
      this.disableDisablePullToRefresh();
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
      const filledInIcon = tenantVariables.filledInIcon || "";
      const bingoSquares = this.state.bingoSquares;
      const bingoSquaresOriginal = this.state.bingoSquaresObject || {};
      let sizeOfBingoSquares = 100;
      if(bingoSquares && bingoSquares.length > 0){
        const numberOfSquaresInRow = Math.sqrt(bingoSquares.length);
        const numberOfGutters = numberOfSquaresInRow - 1;
        const marginSpace = numberOfGutters * 5;
        sizeOfBingoSquares = (300 - marginSpace) / numberOfSquaresInRow;
      }
      return(
          <>
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt="" style={{margin:0}}/>
              </div>
              <div className="spacer-md"/>
              {bingoSquares && bingoSquares.length > 0 &&
                <div style={{display:'flex', flexDirection:'row', flexWrap: "wrap", width:330, margin: "auto"}}>
                  {
                    bingoSquares.sort((a, b) => a.location - b.location).map(function(item, index){
                        if(!item.id){
                          return
                        }
                        const originalItem = bingoSquaresOriginal[item.id] || {};
                        const settings = {
                          width: sizeOfBingoSquares,
                          height: sizeOfBingoSquares,
                          image: originalItem.image,
                          finishPercent: 50,
                          brushSize:5,
                          onComplete: () => this.setSquareScratched(item,index)
                        };

                        return(
                            <div key={index} className="flip-container" style={{display:'flex', flexDirection: 'column', margin:5, width: sizeOfBingoSquares, height: sizeOfBingoSquares, flexGrow:1, alignItems: "center"}}>
                              <div style={{width: sizeOfBingoSquares, height: sizeOfBingoSquares}}>
                                {originalItem.completed || item.scratched ?
                                    <div style={{backgroundImage: "url(" + originalItem.image + ")"}}>
                                      <img width={sizeOfBingoSquares} height={sizeOfBingoSquares} src={originalItem.checkedImage || filledInIcon || sampleCardImage} alt=""/>
                                    </div>
                                  :
                                    <div key={index} className="scratch-image" id="scratch-image" onTouchStart={(e)=>this.handleTouchStart(e)} onTouchEnd={(e)=>this.handleTouchEnd(e)}>
                                      <ScratchCard {...settings}>
                                        <div className="cover-image-container">
                                          <img width={sizeOfBingoSquares} height={sizeOfBingoSquares} src={originalItem.checkedImage || filledInIcon || sampleCardImage} className="cover-image-control" alt=""/>
                                        </div>
                                      </ScratchCard>
                                    </div>
                                }
                              </div>
                            </div>
                        )
                    }, this)
                  }
                </div>
              }
              <div style={{height:"100px", width:"1px"}}/>
          </>
        )
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.active && !this.state.active){
        console.log("Restart")
        this.removeReactAlert();
        this.restartClicked();
      }
    }

    restartClicked(){
      this.setState({
          bingoSquares: []
      })
    }

    render() {
      const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const link = tenantRules.rulesAndRegsLink;
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        return (
            <Navigate to="/login"/>
        )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
        this.logout();
      }
      if(!verifiedAge){
        return (
            <Navigate to="/age_gate"/>
        )
      }
      if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
        )
      }
      let renderMainScreen = this.renderHoldingScreen()
      if(this.state.active === true && !this.state.ended && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    <div style={{display: showHowToPlayButton ?"": "none"}}>
                      <button onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank')}} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>
                        <Textfit mode="single" max={16}>
                          How To Play
                        </Textfit>
                      </button>
                    </div>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{rulesInAppButtonText}</button>
                    </div>
                    <div style={{visibility: projectId === "seahawksbingogame" ? "hidden":"visible"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>LOG OUT</button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
